/* Set the height to full screen */
.campaign-details-page {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* ScrollArea that takes up the remaining height */
.table-container {
  flex: 1;
  overflow: auto;
}

.refreshIconContainer {
  cursor: pointer;
  /* color: var(--mantine-color-blue-4); */
  color: var(--salv-dark-6);
}

.refreshIconContainer:hover {
  cursor: pointer;
  color: var(--mantine-color-blue-8);
}
