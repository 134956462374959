.web-call-outer-container {
    width: 100vw;
    height: 100vh;
    background-color: var(--salv-dark-9);
    color: white;
    font-size: 16px;
    position: relative;
}

.web-call-container {
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    width: 100%;
    max-width: 1600px;
    position: relative;
}

.web-call-inner-container {
    width: 100%;
    height: 100%;
    padding: 40px;
    padding-bottom: 100px;
    position: relative;
}


.web-controls-area {
    width: 100%;
    position: absolute;
    padding-left: 40px;
    padding-right: 40px;
    bottom: 35px;
    display: flex;
    justify-content: space-between; /* Centers items horizontally */
    align-items: center; 
    text-align: center;
}


@media (max-width: 600px) {
    .web-call-inner-container {
        height: 55%;
        padding: 20px;
    }
    .phone-action-button {
        width: 100%; /* Make buttons take full width */
        text-align: center;
    }
    .web-controls-area {
        flex-direction: column;
        gap: 14px;
        bottom: 20%;
    }
    .web-call-outer-container {
        overflow: scroll;
    }
}

.phone-action-button {
  padding: 6px 20px;
  background: var(--salv-dark-7);
  color: white;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  opacity: 0.86;
  transition: opacity 0.3s ease;
  font-weight: 500;
}

.phone-action-button:hover {
    background: var(--salv-dark-6);
}

.begin-button {
    background: #0da037;
}

.begin-button:hover {
    background: #20b44a;
}

.end-button {
    background: #e22c2c;
}

.end-button:hover {
    background: #f04040;
}

.phone-action-button:disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.expired-message {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
  }

.white-box {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow */
    overflow: hidden;
}


  /* Wrapper for the main content (RetellWebCallHandler and info panel) */
.call-content-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}

/* Left section: RetellWebCallHandler fills remaining space */
.retell-handler-wrapper {
    flex: 1;
    height: 100%;
    background-color: var(--salv-dark-8); /* Optional: Set a background color */
    margin-right: 20px; /* Optional: Add some spacing between sections */
}

/* Right section: White rectangle with fixed width */
.info-panel {
    width: 300px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    transition: width 0.22s ease-in-out, opacity 0.22s ease-in-out;
}

.panel-hidden {
  width: 0;
  opacity: 0;
  pointer-events: none;
}

/* Header for the info panel */
.info-header {
    font-size: 16px;
    font-weight: semi-bold;
    margin-bottom: 20px;
    color: var(--salv-dark-9);
    text-align: center;
}

/* Container for the messages */
.messages-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align messages to the top */

    overflow-y: auto; /* Enable vertical scrolling */
    height: calc(100% - 80px);

    scroll-behavior: auto; /* Enable smooth scrolling by default */
}

/* Individual message box */
.message {
    margin: 14px;
    margin-bottom: 4px;
    background-color: rgb(241,243,244); /*#f0f0f0; */ /* var(--salv-dark-1);*/ /* #f0f0f0; */
    color: #333;
    padding: 10px 15px;
    border-radius: 8px;
    font-size: 14px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow */
}

.messages-container.auto-scrolling {
    overflow-y: auto; /* Show scrollbar only when scrolling */
    scroll-behavior: smooth;
}
