.clickable-icon {
    opacity: .3;
}

.clickable-icon:hover {
    opacity: 1;
}

.thumb-icon {
    height: 20px;
}

.thumb-up {
    color: var(--mantine-color-green-4);
}

.thumb-down {
    color: var(--mantine-color-red-4);
}

.comment-icon {
    color: var(--mantine-color-gray-5);
    height: 22px;
}
